

















































































































































































































































import { COUNTRIES } from '@/shared/constants'
import _ from 'lodash'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { UserRole } from '@/shared/model/user.model'
import {
  CertificationAuthority,
  CertificationAuthorityRole,
  ICertificationAuthority,
  IUserSubsegment,
} from '@/shared/model/certificationAuthority.model'
import certificationService from '@/shared/services/certificationService'
import userService from '@/shared/services/userService'

export default Vue.extend({
  components: {
    ImageUpload: () => import('@/views/app/_components/ImageUpload.vue'),
    TrainerTable: () => import('./TrainerTable.vue'),
    ScrollIndicator: () => import('@/views/app/_components/ScrollIndicator.vue'),
    CertificateTemplatesTable: () => import('../certificate/CertificateTemplatesTable.vue'),
    CertificatesTable: () => import('../certificate/CertificatesTable.vue'),
  },
  data: () => ({
    image: null as any | string,
    imageContentType: null as any | string,
    certificationAuthorityFromDB: new CertificationAuthority() as ICertificationAuthority,
    selectedCertificationAuthority: null as ICertificationAuthority | null,
    countries: COUNTRIES,

    breadcrumbItems: [] as any[],
  }),
  computed: {
    ...mapGetters({
      account: 'account',
      certificationAuthorities: 'certificationAuthorities',
      certificationAuthority: 'selectedCertificationAuthority',
      certAuthError: 'certificationAuthorityStore/getError',
    }),
    userIsAllowedToEdit: function () {
      if (this.selectedCertificationAuthority && this.account) {
        let userIsCertAuthAdmin = false
        if (this.selectedCertificationAuthority.users) {
          const userFromCertAuth = this.selectedCertificationAuthority.users.find(
            (x: any) => x.user._id === this.account._id,
          )

          if (userFromCertAuth && userFromCertAuth.authorityRoles) {
            userIsCertAuthAdmin = userFromCertAuth.authorityRoles.includes(CertificationAuthorityRole.authorityAdmin)
          }
        }
        if (userIsCertAuthAdmin || this.account.roles.includes(UserRole.admin)) {
          return true
        }
      }
      return false
    },
    userIsSystemAdmin () {
      return userService.userIsSystemAdmin()
    },
    showAutocomplete () {
      if (this.$route.params.id) {
        return false
      }
      if (!this.certificationAuthorities) {
        return false
      }
      if (this.certificationAuthorities && this.certificationAuthorities.length <= 1 && !this.userIsSystemAdmin) {
        return false
      }
      return true
    },
  },
  watch: {
    selectedCertificationAuthority () {
      this.$store.commit('setSelectedCertificationAuthority', this.selectedCertificationAuthority)
      this.updateImageAndBreadcrumbs()
    },
    certificationAuthority () {
      if (!this.selectedCertificationAuthority && this.certificationAuthority) {
        this.selectedCertificationAuthority = this.certificationAuthority
      }
    },
  },
  created () {
    this.getCertificationAuthority()
    if (!this.selectedCertificationAuthority && this.certificationAuthority) {
      this.selectedCertificationAuthority = this.certificationAuthority
    }
    this.updateImageAndBreadcrumbs()
  },
  methods: {
    ...mapActions('certificationAuthorityStore', {
      updateCertificationAuthority: 'updateItem',
    }),
    async clickSave () {
      if (this.certificationAuthority) {
        await this.updateCertificationAuthority({
          id: this.certificationAuthority._id,
          item: this.certificationAuthority,
        })
      }

      if (this.certAuthError) {
        this.$toast.error(this.$t('dialog.an-unexpected-error-occured').toString())
      } else {
        this.$toast(this.$t('dialog.certification-authority-save-successfully').toString())
      }
      this.getCertificationAuthority()
    },

    dataChanged () {
      return _.isEqual(this.certificationAuthorityFromDB, this.certificationAuthority)
    },
    userIsInCertificationAuthority: function () {
      if (this.certificationAuthority && this.certificationAuthority.users) {
        return !!this.certificationAuthority.users.find((x: IUserSubsegment) => {
          if (x.user && this.account) {
            return x.user._id === this.account._id
          }
        })
      }
      return false
    },
    getCertificationAuthority () {
      let id = this.$route.params.id
      if (!id && this.certificationAuthority) {
        id = this.certificationAuthority._id
      }

      if (id) {
        certificationService.getCertificationAuthorityById(this.$store, id).then((result: any) => {
          this.updateImageAndBreadcrumbs()
        })
      }
    },
    updateImageAndBreadcrumbs () {
      this.breadcrumbItems = []
      this.image = ''
      const id = this.$route.params.id
      if (
        this.certificationAuthority &&
        this.certificationAuthority.metadata &&
        this.certificationAuthority.metadata.logo
      ) {
        this.image = `/api/file/${this.certificationAuthority.metadata.logo}`
        this.imageContentType = null
      }
      if (!this.certificationAuthorityFromDB && this.certificationAuthority) {
        this.certificationAuthorityFromDB = _.cloneDeep(this.certificationAuthority)
      }
      if (id && this.certificationAuthority) {
        this.breadcrumbItems = [
          {
            text: this.$t('certification-authorities'),
            to: '/app/certification-authorities',
            exact: true,
          },
          {
            text: `${this.certificationAuthority.name}`,
          },
        ]
      }
      if (this.certificationAuthority) {
        this.selectedCertificationAuthority = this.certificationAuthority
      }
    },
    setImage: function (blobContent: any) {
      if (blobContent && blobContent.image) {
        this.image = blobContent.image
        if (blobContent.imageContentType) {
          this.imageContentType = blobContent.imageContentType
        } else {
          this.imageContentType = null
        }
        this.image = blobContent.image
        if (this.certificationAuthority && this.certificationAuthority.metadata) {
          this.certificationAuthority.metadata.logo = blobContent.image
        }
      }
    },
  },
})
