/* eslint-disable */
import { IUser } from './user.model'

export enum CertificationAuthorityRole {
  trainer = 'trainer',
  trainerRO = 'trainerRO',
  authorityAdmin = 'authorityAdmin',
  authorityAdminRO = 'authorityAdminRO',
}

export interface IUserSubsegment {
  user?: IUser
  authorityRoles?: CertificationAuthorityRole[]
}

export interface ICertificationAuthority {
  _id?: string
  name?: string
  email?: string
  metadata: {
    phone?: string
    address?: {
      street?: string
      zip?: string
      city?: string
      country?: string
    }
    logo?: string | null | undefined
  }
  createdAt?: Date
  updatedAt?: Date
  users?: IUserSubsegment[]
}

export class CertificationAuthority implements ICertificationAuthority {
  constructor(
    public _id?: string,
    public name?: string,
    public email?: string,
    public metadata: {
      phone?: string
      address?: {
        street?: string
        zip?: string
        city?: string
        country?: string
      }
      logo?: string | null | undefined
    } = {},
    public createdAt?: Date,
    public updatedAt?: Date,
    public users?: IUserSubsegment[]
  ) {
    this.users = []
    this.metadata = {}
    this.metadata.address = { street: '' }
    return this
  }
}
