import { types } from '@/store/utils/resource'
import axios from 'axios'
import store from '../../store'
import { CertificateTemplate } from '../model/certificateTemplate'
import { CertificationAuthority, ICertificationAuthority } from '../model/certificationAuthority.model'
// eslint-disable-next-line
import { ICompany } from '../model/company.model'
// eslint-disable-next-line
import { IUser, User } from '../model/user.model'

class CertificationService {
  // getAllCertificationPaginated (query: any) {
  //   store.dispatch('companyStore/getList', query)
  // }
  getUsersCertificationAuthority (id: string) {
    return axios.get(`/api/usersCertificationAuthority/${id}`)
  }

  getMyCertificationAuthorities (store: any, selected: string | null = null) {
    return new Promise((resolve) => {
      axios.get('/api/mycertificationAuthorities').then((result) => {
        if (result.data && result.data.length) {
          store.commit('setCertificationAuthorities', result.data)
          if (selected) {
            const foundCertAuthority = result.data.filter((x: ICertificationAuthority) => x._id === selected)
            if (foundCertAuthority) {
              store.commit('setSelectedCertificationAuthority', foundCertAuthority)
            } else {
              console.error('Certification Authority not found after updating certAuth on /app/certification-authority')
            }
          } else {
            store.commit('setSelectedCertificationAuthority', result.data[0])
          }
        } else {
          store.commit('setCertificationAuthorities', null)
          store.commit('setSelectedCertificationAuthority', null)
        }
        resolve(true)
      })
    })
  }

  getCertificationAuthorityById (store: any, id: string) {
    return new Promise((resolve) => {
      axios.get('/api/certificationAuthority/' + id).then((result) => {
        if (result.data) {
          store.commit('setSelectedCertificationAuthority', result.data)
          resolve(result.data)
        } else {
          store.commit('setSelectedCertificationAuthority', null)
          resolve(null)
        }
      })
    })
  }

  getCertificateTemplates (store: any, id: string) {
    return new Promise((resolve) => {
      axios
        .get('/api/certificationauthority/' + id + '/template', {
          // here we default to sortIndex for the sort in the certificateWizard
          params: { l: 0, lo: -1, sf: 'sortIndex', so: 'asc' },
        })
        .then((result) => {
          if (result.data) {
            store.commit(`certificateTemplateStore/${types.GET_LIST.SUCCESS}`, result)
            resolve(result.data)
          } else {
            store.commit(`certificateTemplateStore/${types.GET_LIST.SUCCESS}`, null)
            resolve(null)
          }
        })
    })
  }

  getUsersCertificatsByUserId (id: string) {
    return new Promise((resolve) => {
      axios.get('/api/certificate/' + id).then((result) => {
        if (result.data) {
          resolve(result.data)
        }
        resolve(null)
      })
    })
  }

  getCertificationAuthorities (): Promise<CertificationAuthority[]> {
    return new Promise((resolve) => {
      axios.get('/api/certificationAuthorities').then((result) => {
        if (result.data && result.data.items) {
          store.commit('setCertificationAuthorities', result.data.items)
          resolve(result.data.items)
        } else {
          resolve([])
        }
      })
    })
  }

  // following is not using an id because the backend throws back the company related to the user
  // getCompany (store: any, id: string | null = null) {
  //   store.dispatch('fetchCompany', id)
  // }
}

const certificationService = new CertificationService()
export default certificationService
